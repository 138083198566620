processZjedzmyWidget();

window.addEventListener('message', function (event) {
    if (event.data.action == "closeQuickBookingPanel") {
        window.parent.document.getElementById('zjedzmy-widget-wrap').parentNode.removeChild(window.parent.document.getElementById('zjedzmy-widget-wrap'))
    }
}, false);

function processZjedzmyWidget() {
    var container = document.querySelector('.zjedzmy-widget');
    var id = container.getAttribute('data-id');
    var url = container.getAttribute('data-site-url')+(container.getAttribute('data-site-url').slice(-1) != '/' ?'/':'');
    var selector = container.getAttribute('data-selector');
    var standalone = container.getAttribute('data-standalone');
    var target_url = url+'booking/' + id +'?' + (standalone?'ref=standalone':'ref=widget') + (selector?'&selector=1':'');

    var css = document.createElement('link')
    css.setAttribute('href', url+'widget/widget.css')
    css.setAttribute('rel', 'stylesheet');
    document.head.appendChild(css)

    container.innerHTML += '<div class="zjedzmy-cta zjedzmy-toggle"><img src="" class="zjedzmy-logo hidden" /></div><span class="zjedzmy-iframe-close zjedzmy-toggle zjedzmy-show-hide">&times;</span><div class="zjedzmy-iframe-wrap zjedzmy-show-hide"><iframe class="zjedzmy-iframe" src="" frameborder="0"></iframe></div>';

    var config = document.createElement('script');
    config.setAttribute('src', url+ 'widget/'+id+'/config');
    config.setAttribute('type', 'text/javascript');
    document.head.appendChild(config);

    document.querySelector('.zjedzmy-toggle').onclick = function () {
        // WORKAROUND for safari: https://stackoverflow.com/questions/58525719/safari-not-sending-cookie-even-after-setting-samesite-none-secure
        var isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
        if(isSafari) {
            window.location.href = target_url;
        }
        else {
            document.body.insertAdjacentHTML('afterbegin', '<div id="zjedzmy-widget-wrap" class="zjedzmy-widget-bg" style="display: flex;position: fixed;inset: 0px;width: 100%;height: 100%;overflow-y: auto;border: 0px;margin: 0px;padding: 0px;z-index: 2147483647;transition: opacity 150ms ease 0s;align-items: center;justify-content: center;opacity: 1;background: rgb(142 142 142 / 70%);backdrop-filter: blur(10px);"><div id="quick_booking_loading_indicator"><img src="'+url+ 'images/logo/zjedzmy/logo_vert_split.svg" class="zjedzmy-logo" /></div><iframe id="quick_booking_iframe" allowtransparency="true" frameborder="0" width="100%" height="100%" marginwidth="0" marginheight="0" src="'+target_url+ '" style="display: block; position: absolute; inset: 0px; width: 100%; height: 100%; overflow-y: auto; border: 0px; margin: 0px; padding: 0px; background: transparent;  z-index: 9999;"></iframe></div>');
            var iframe = document.getElementById("quick_booking_iframe");
            var loading = document.getElementById("quick_booking_loading_indicator");
            iframe.style.display = "none";
            loading.style.display = "block";
            iframe.onload = function() {
                iframe.style.display = "";
                loading.style.display = "none";
            };
        }
    };
}
